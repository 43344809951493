import {
  faBars,
  faQuestionCircle,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
  faExpand,
  faLink,
  faSearch,
  faEnvelope,
  faTimes,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(
  fab,
  faBars,
  faCaretDown,
  faCaretUp,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faExpand,
  faInfoCircle,
  faLink,
  faSearch,
  faQuestionCircle,
  faTimes,
  faCircleNotch,
);
