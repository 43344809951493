import React from "react";
import { graphql } from "gatsby";

import { SharedLayout } from "layouts/SharedLayout";
import SEO from "components/seo";
import {
  StyledH1,
} from "styles/Typography";
import { StyledSection } from "styles/Containers";
import { CarSearch } from "components/CarSearch";
import "helpers/fontAwesome";

export const query = graphql`
  query SearchPage {
    allCarData(sort: { fields: CoalescedProbDeath }) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedRscRating
        VehicleSizeCategory
      }
    }
  }
`;

const SearchPage = ({ data }) => {
  const allCarData = data.allCarData.nodes;
  return (
    <SharedLayout>
      <SEO title="Search" />
      <StyledH1>Search</StyledH1>
      <StyledSection>
        <CarSearch carSearchData={allCarData} />
      </StyledSection>
    </SharedLayout>
  );
};

export default SearchPage;
